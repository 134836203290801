.sms-container{
    background-color: rgb(38, 35, 35);
    max-width: 80%;
    margin: auto;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
}

.messages{
    display: flex;
    gap: 2rem;
}

.messages p{
    background-color: crimson;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    font-size: small;
}

.sms-form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sms-form input{
    max-width: 350px;
    height: 2rem;
}
