.hospitals-container{
    margin: 2rem 7%;
    padding: 2rem;
    display: grid;
    gap: 1rem;
    background-color: rgb(38, 35, 35);
}

.hospitals-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

.hospital-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    border: 1px solid white;
    background-color: rgb(31, 30, 30);
    padding: 1rem;
    font-size: small;
}

.hospital-card h4{
    font-size:medium;
    text-transform: uppercase;
}

.onboard{
    font-weight: bolder;
    text-transform: uppercase;
    text-decoration: underline;
}

.add-btn{
    justify-self: right;
    font-size: small;
    border: 1px solid red;
    padding: 7px;
}

.add-btn:hover{
    background-color: white;
}

.viewedit{
    background-color: crimson;
    padding: 0.5rem;
}

.viewedit:hover{
    background-color: white;
}

.publiclink{
    color: yellow;
}



@media only screen and (max-width:768px) {
    .hospitals-wrapper{
        grid-template-columns: 1fr 1fr;
    }

    .hospitals-container{
        margin: 1rem 3%;
    }
}

@media only screen and (max-width:568px) {
    .hospitals-wrapper{
        grid-template-columns: 1fr;
    }
}