.hform{
    margin: 2rem 5%;
    display: grid;
    gap: 0.5rem;
    position: relative;
}

.back{
    width: fit-content;
    justify-self: right;
}


.form-container{
    background-color: rgb(38, 35, 35);
}


.hospital-form{
    display: grid;
    gap: 1rem;
    padding: 1.5rem 0;
}

.upload-image{
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.load{
    margin: auto;
    margin-top: 10rem;
}

.h-img{
    background-color: white;
    height: 150px;
    width: 150px;
    border: white;
    object-fit:fill;
    color: black;
}

.upload-btn{
    height: fit-content;
    align-self: center;
    padding: 0.5rem;
}

.input-flex{
    display: flex;
    gap: 1rem;
    justify-content:space-evenly
}

.one-input{
    flex-basis: 40%;
    display: grid;
    gap: 0.5rem;
}

.single-input{
    width: 88%;
    display: grid;
}

.one-input input{
    height: 2.5rem;
}

textarea{
    height: 7rem;
    resize: vertical;
} 

input, textarea{
    background-color: rgb(192, 182, 182);
}
.button-flex{
    justify-self: right;
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    margin-right: 10%;
}
.hform .link, button{
    background-color: black;
    padding: 0.7rem 1.2rem;
}

.doc-container{
    background-color: rgb(42, 49, 55);
    padding: 1rem 0;
}


.sbtn{
    position: relative;
}


.doclist{
    display: flex;
    border: 1px solid white ;
    background-color: black;
    padding: 1rem;
    justify-content: space-between;
    width: 50%;
    margin: auto;
}

.doc-info{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.spec{
    font-size: small;
    color: yellow;
}

.delEdit{
    display: flex;
    gap: 2rem;
    align-self: center;
}

.span-btn{
    color: red;
    text-decoration: underline;
    cursor: pointer;
}
.span-btn:hover{
    color: blue;
}

.add-doc{
    padding: 1rem ;
    background-color: crimson;
    width: fit-content;
    cursor: pointer;
}

.add-doc:hover{
    background-color: red;
}

@media only screen and (max-width:768px) {

    .input-flex{
        padding: 0 1rem;
        flex-direction: column;
        gap: 1rem;
    }

    .doclist{
        width: 300px;
    }

    .upload-image{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .single-input{
        width: 100%;
    }
}