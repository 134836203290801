.nav-container{
    color: white;
    background-color: black;
    padding-left: 2rem;
    padding-right: 3rem;
}

.nav-container .wrapper{
    height: 4rem;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
}

.wrapper .list{
    display: flex;
    list-style-type: none;
    padding-left: 0;
    gap: 2rem;
    z-index: 100;
    font-size: .9rem;
}

.link:hover{
    color: red;
}

.menu-icon{
    display: none;
    background-color: lightslategray;
}

.logout{
    color: red;
    text-decoration: underline;
}


@media only screen and (max-width: 768px) {


    .cards{
        flex-direction: column;
        align-items: center;
    }

    .wrapper .list{
        position: absolute;
        flex-direction: column;
        background-color: #393E46;
        width: 15rem;
        top: 0;
        padding: 2rem;
        padding-top: 7rem;
        bottom: 0;
        right: -20rem;
        
    }

   
    .list .link{
        padding: 1rem;
    }
    

    .list.menu-active{
        right: 0;
    }


    .menu-icon{
        display: block;
        border-radius: 2px;
        z-index: 1000;
        font-size: 1.8rem;
        cursor: pointer;
        padding: 3px;
        align-self: center;
    }

    .menu-icon:hover{
        background-color: gray;
    }

    
}


@media only screen and (max-width: 768px){
    
}