.login-container{
  background-color:#393E46;
  height: 100vh;
  position: relative;
}

.form-wrapper{
  position: absolute;
  width: 100%;
  margin-top: 12rem;
}

.form-wrapper h1{
  margin-bottom: 1rem;
  text-align: center;
  color: white;
}

.login-form{
  max-width: 25rem;
  display: grid;
  gap: 1rem;
  top: 35%;
  margin-right: auto;
  margin-left: auto;
  padding: 5px;
}

.input-row{
  display: flex;
  width: 100%;
  overflow: hidden;
}
.input-row > *{
  background-color: gray;
  padding: 0.9rem;
  flex-basis: auto;
  border: none;
  height: inherit;
}

.input-row input{
  background-color: lightgray;
  width: 100%;
  font-size: large;
}

.input-row input:focus{
  outline: none;
}

.login-form button{
  height: 3rem;
  width: 100%;
  background-color: #FB2576;
  border: none;
  color: white;
  font-weight: 800;
  cursor: pointer;
}

.login-form button:hover{
  background-color: #D23369;
}

.login-form > *{
  border-radius: 5px;
}