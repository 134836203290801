.enquiries-container{
    margin: 2rem 7%;
    padding: 2rem;
    display: grid;
    gap: 1rem;
    background-color: rgb(38, 35, 35);
}

.enquiries-wrapper{
    display: flex;
    gap: 1rem;
    flex-wrap:wrap;
    justify-content: space-evenly;
}

.enquiry-card .file{
    color: blue;
}

.enquiry-card{
    position: relative;
    flex-basis: 260px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    border: 1px solid white;
    background-color: rgb(31, 30, 30);
    padding: 1rem;
    font-size: small;
}

.del-icon{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
}

.enquiry-card span{
    color: yellow;
}