@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

* {
  transition: 0.4s;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  color: white;
}

.link{
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.form-btn{
  background-color: crimson;
}

.form-btn:hover{
  background-color: lightgray;
  color: crimson;
  cursor: pointer;
}

input, textarea{
  color: black;
  border: none;
}

input:focus, textarea:focus{
  outline: none;
}

.skeleton{
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0%{
    background-color: hsl(200, 20%, 70%);
  }
  100%{
    background-color: hsl(200, 20%, 950%);
  }
}
