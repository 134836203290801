.pass-update{
    margin: auto;
    text-align: center;
    height: 50%;
    max-width: 390px;
    padding: 2rem;
    margin-top: 8rem;
    background-color: rgb(38, 35, 35);;
}

.pass-form{
    display: grid;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
}

.pass-form input{
    height: 2rem;
}